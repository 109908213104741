.deflat-wrapper {
  width: 100%;
  background: #f6f9ff;
  // padding: 55px 50px;
  font-family: "GT America Bold";
  font-size: 24px;
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    // height: 0.5em;
    @media (max-width: 450px) {
      width: 0px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(10, 10, 10, 0.1);
    border-radius: 10px;
  }
}

.padded-container {
  padding: 25px 50px;
  @media (max-width: 450px) {
    padding: 35px 15px !important;
    // overflow: hidden;
  }
  @media (max-width: 1280px) {
    padding: 55px 35px 0px;
  }
}

.deflat-banner-container {
  display: flex;
  height: 278px;
  background-color: #bdb9ff;
  padding: 0 65px;
}

.deflat-banner-img {
  width: 100%;
  height: 100%;
}

.deflat-banner-cta {
  font-family: "GT America Bold";
  font-size: 24px;
  color: #311e86;
  margin-top: 71px;
}

.deflat-banner-text {
  width: 600px;
}

.banner-btn {
  width: 187px;
  height: 52px;
  border-radius: 5px;
  background-color: #311e86 !important;
  margin-top: 24px;
}

.deflat-button-cont {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-right: 25px;

  .deflat-go-button {
    width: 187px;
    height: 52px;
    border-radius: 5px;
    background-color: #311e86 !important;
  }
}

.def-three-wrapper {
  background-color: #7ebaff;
  display: flex;
  width: 100%;
  height: 468px;
  padding: 0 50px;
  position: sticky;
  position: -webkit-sticky;
  top: -10%;
  z-index: 100;
  justify-content: space-between;
}

.static-bots-container {
  // padding: 80px 30px 0px 30px;
  position: relative;
  width: 377px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  // overflow: hidden;
  .static-bot-img {
    width: 100%;
    height: auto;
  }
  svg {
    font-size: 42px;
  }
  .bot-one-arrow {
    position: absolute;
    top: 50%;
    right: -20%;
    z-index: 100;
  }

  .bot-two-arrow {
    position: absolute;
    top: 50%;
    left: -20%;
    z-index: 100;
  }
}

.def-three-scene {
  width: 50%;
  position: relative;
  height: 100%;
}

.layers-wrapper {
  padding: 30px 50px;
  width: 100%;
}

.layers-container {
  display: flex;
  justify-content: space-between;
}

.layer-item {
  background: #ffffff;
  border-radius: 16px;
  padding: 15px;
  width: 303px;
  height: 123px;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  box-shadow: 5px 1px 15px 1px grey;
  // box-shadow: 20px 20px 60px #d1d4d9, -20px -20px 60px #ffffff;
  align-items: center;
  .layer-logo {
    background: #bdb9ff;
    border-radius: 16px;
    height: 93px;
    min-width: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #1c1c8d;
    }
  }
  .layer-desc {
    display: flex;
    flex-direction: column;
    .title {
      font-family: "GT America Regular";
      font-size: 13px;
      line-height: 19px;
    }
    .desc {
      font-family: "DM Sans Bold";
      font-size: 20px;
      line-height: 30px;
    }
    .layer-button {
      background-color: #311e86 !important;
      width: 105px;
      margin-top: 12px;
    }
  }
}

.page-top-controls {
  display: flex;
  justify-content: space-between;
  margin: 56px 88px 48px;

  .control-btn-white {
    background-color: #ffffff !important;
    color: #311e86 !important;
    border-color: #311e86 !important;
    height: 52px;
    width: 151px;
    svg {
      //   color: #311e86 !important;
      font-size: 24px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .blue-bg {
    background-color: #311e86 !important;
    color: #ffffff !important;
  }

  .lg-btn {
    width: 254px;
  }
}

.controls-btn-cont {
  display: flex;
  column-gap: 30px;
}

.layers-heading {
  margin-bottom: 35px;
  svg {
    margin-right: 20px;
  }
}

.empty-layer-card {
  font-family: "GT America Regular";
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
}

.pulsate {
  animation: pulse 2s infinite;
  // transform: scale(1);
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(51, 255, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.def-ranking {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 15%;
  left: 0;
  .def-ranking-val {
    font-family: "GT America Bold";
    font-size: 36px;
    color: #19233c;
  }
  .def-ranking-title {
    font-family: "GT America Medium";
    font-size: 14px;
    color: #ffffff;
  }
  img {
    width: 25px;
  }
}

.new-bot-ranking {
  left: 10%;
}

.rank-info {
  font-size: 15px;
}

.deflat-warning-cont {
  font-family: "GT America Regular";
  font-size: 18px;
  color: #19233c;
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  .deflat-warning {
    padding: 24px 25px;
    background-color: #ffffff;
    width: max-content;
    display: inline-block;
    border-radius: 16px;
    // align-items: center;
    box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
    -webkit-box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
    -moz-box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
    svg {
      color: green;
      font-size: 24px;
      margin-right: 5px;
    }
    a {
      text-decoration: none;
    }
  }
  // text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
  // -webkit-box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
  // -moz-box-shadow: -1px 5px 20px 5px rgba(111, 135, 242, 0.26);
}

.def-clan-info {
  position: absolute;
  bottom: 0;
  left: 40%;
  background-color: #ffffff;
  font-family: "GT America Regular";
  font-size: 14px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 12px 60px 12px 60px;
  align-items: center;
  color: #19233c;
  .clan-info {
    font-family: "GT America Bold";
    font-size: 16px;
  }
  .subclan-info {
    font-size: 14px;
  }
}

.static {
  left: 30%;
}

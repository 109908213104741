.career-wrapper {
    padding: 64px 88px;
    background: #F6F9FF;
    width: 100%;
    overflow-y: auto;
    @media (max-width: 1279px) {
        padding: 15px;
    }
}

.career-content {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
}

.career-search {
    display: flex;
    width: 100%;
    padding: 24px 29px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
    border-radius: 16px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1279px) {
        flex-direction: column;
        row-gap: 15px;
        padding: 15px;
    }
    .career-text-input {
        display: flex;
        flex-grow: 1;
        align-items: center;
        column-gap: 50px;
        @media (max-width: 1279px) {
            flex-direction: column;
            row-gap: 15px;
            width: 100%;
        }
     input {
        width: 626px;
        height: 52px;
        background: #F6F9FF;
        border: 1px solid #B7C4DD;
        border-radius: 5px;
        box-shadow: none;
        font-family: "GT America Regular";
        @media (max-width: 1279px) {
            width: 100%;
        }
     }
    }
    .career-search-label {
        font-family: "GT America Bold";
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #19233C;
        white-space: nowrap;
    }

    .career-search-btn {
        width: 186px;
        height: 52px;
        background: #311E86 !important;
        border-radius: 5px;
        box-shadow: none !important;
        font-family: "GT America Regular";
    }
}

.career-blockbot-view {
    display: flex;
    column-gap: 38px;
    @media(max-width: 1279px) {
        flex-direction: column;
        row-gap: 20px;
    }
}

.career-img-container {
    width: 38%;
    height: 588px;
    background-color: #96C9E5;
    overflow: hidden;
    border-radius: 16px;
    img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
    @media(max-width: 1279px) {
        width: 100%;
        height: 430px;
    }
}

.career-stats-container {
    display: flex;
    flex-direction: column;
    width: 59%;
    row-gap: 35px;
    @media(max-width: 1279px) {
        width: 100%;
    }
    .career-stats-name-details {
        padding: 20px 40px;
        background-color: #fff;
        display:flex;
        justify-content: space-between;
        box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
        border-radius: 16px;
        align-items: center;
        .bot-stats-name-detail {
            color: #19233C;
            .stats-name {
                font-family: "GT America Bold";
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
            }
            .stats-clan {
                font-family: "GT America Regular";
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }
        }
        .stats-socials {
            width: 25px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.stats-details-container {
    padding: 35px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
    border-radius: 16px;
    hr {
        margin: 32px 0;
    }
    .stats-attributes {
        color: #19233C;
        font-family: "GT America Bold";
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .attr-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 18px 24px;
        font-family: "GT America Medium";
        margin-top: 24px;
        .attr-power-label {
            display: flex;
            width: 48%;
            padding: 10px 15px;
            border-radius: 5px;
            justify-content: space-between;
            align-items: center;
            img {
                margin-right: 10px;
              }
              @media(max-width: 1279px) {
                width: 100%;
            }
        }
    }
}

.career-stats-title {
    font-family: 'GT America Bold';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 30px;

color: #19233C;

}

.career-stats-table {
    margin-top: 24px;
}

.career-table-head {
    background: #F6F9FF;
    border-radius: 5px;
    font-family: 'GT America Medium';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #19233C;
}

.career-empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background: #F6F9FF;
    border-radius: 5px;
    font-family: 'GT America Bold';
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #19233C;
    padding: 12px;
}

.career-table-tr {
    font-family: "GT America Regular";
}
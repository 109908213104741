.header-navbar {
  box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
  padding: 0 70px;
  @media (max-width: 450px) {
    display: none;
    padding: 0;
  }
  @media (max-width: 1440px) {
    padding: 0 70px;
  }
  @media (max-width: 1440px) {
    padding: 0 35px;
  }
}

.header-nav-bar {
  display: flex;
  width: 100%;
  //   box-shadow: 0px 4px 4px 0px #cdd8ed8a;
  // box-shadow: 0px 4px 4px rgba(205, 216, 237, 0.54);
}

.header-container {
  margin-left: 0px;
}

.header-bb-logo {
  border: 3px solid #4d4eb8;
  border-radius: 30px;
  width: 52px;
  height: 52px;
  background-image: url("../assets/images/bb_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  cursor: pointer;
}

.header-title {
  margin-left: 23px;
  color: #19233c;
  font-size: 24px;
  line-height: 30px;
  font-family: "GT America Bold";
  cursor: pointer;
  @media (max-width: 450px) {
    margin-left: 0;
  }
}

.header-button {
  font-family: "GT America Regular";
  font-weight: bold;
  margin-right: 32px;
  border-radius: 5px !important;
  font-size: 16px !important;
  width: 187px;
  height: 52px;
  line-height: 18px;
  background-color: #311e86 !important;
  .btn {
    background-color: #311e86 !important;
  }
  .dropdown-menu {
    background-color: #311e86;
    width: inherit;
    // margin-top: 3px;
    offset: 3px;
    .dropdown-item {
      color: #ffffff;
      font-family: "GT America Bold";
      font-size: 16px;
      line-height: 18px;
      //   margin-bottom: 12px;
      padding: 10px 26px;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: #311e86;
    }
  }
  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }
  .btn:disabled {
    opacity: 0.5;
  }
}

.header-address {
  display: flex;
  align-items: center;
  font-family: "DM Sans Regular";
  font-size: 16px;
  line-height: 20px;
  color: #707d9b;
}

.header-profile-icon {
  margin-left: 32px;
}

.mobile-nav-collapse {
  color: #311e86;
  font-family: "DM Sans Bold";
  background-color: #b9cdf66b;
  margin-top: 10px;
  .mobile-nav-item {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f6f9ff;
  }
}

.header-nav-items {
  font-family: "DM Sans Bold";
  font-size: 18px;
  a {
    text-decoration: none;
    color: #19233c;
    margin-right: 75px;
  }
  sup {
    font-size: 12px;
    top: -0.9em;
    color: gray;
    font-family: "GT America Regular";
  }
}

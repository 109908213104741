@import "./typography.scss";
@import "./header.scss";
@import "./sidebar.scss";
@import "./showcase.scss";
@import "./faq.scss";
@import "./deflatinator.scss";
@import "toast.scss";
@import "./career.scss";

.main-container {
  display: flex;
  height: calc(98vh - 20px);
  @media (min-height: 900px) {
    height: calc(88vh);
  }
}

.main-wrapper {
  width: 100%;
  background: #f6f9ff;
  padding: 55px 50px;
  font-family: "GT America Bold";
  font-size: 24px;
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    // height: 0.5em;
    @media (max-width: 450px) {
      width: 0px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(10, 10, 10, 0.1);
    border-radius: 10px;
  }
  @media (max-width: 450px) {
    padding: 35px 15px !important;
    // overflow: hidden;
  }
  @media (max-width: 1280px) {
    padding: 55px 35px 0px;
  }
}

.bots-container {
  display: flex;
  grid-gap: 30px;
  flex-wrap: wrap;
  // margin-top: 30px;
  @media (max-width: 450px) {
    padding: 0 1rem;
    // overflow: hidden;
  }
  @media (min-height: 900px) {
    padding-bottom: 50px;
  }
}

.bots-card {
  width: 23%;
  // height: 350px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px 0px #cdd8ed80;
  cursor: pointer;
  will-change: transform, transition, width;
  @media (max-width: 1440px) {
    width: 31%;
  }
  @media (max-width: 1339px) {
    width: 30%;
  }
  @media (max-width: 1610px) {
    width: 31%;
  }
  @media (max-width: 450px) {
    width: 100%;
    // overflow: hidden;
  }
  @media (max-width: 1229px) {
    width: 48%;
  }
  @media (max-width: 1080px) {
    width: 47%;
  }
}

.card-sm {
  width: 18%;
  @media (max-width: 1440px) {
    width: 23%;
  }
}

.bots-card-img-cont {
  height: 251px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bots-card-incomplete {
  display: flex;
  height: 251px;
  justify-content: center;
  align-items: center;
  background-color: #bdb9ff;
  overflow: hidden;
  svg {
    width: 50%;
    height: auto;
    color: grey;
    animation: rotation 4s infinite linear;
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.bots-details {
  padding: 24px;
  // display: flex;
  color: #19233c;
  justify-content: space-between;
  align-items: center;
  // flex-direction: column;

  .bot-name {
    font-family: "GT America Bold";
    font-size: 14px;
    line-height: 20px;
  }
  .bot-clan {
    font-family: "GT America Regular";
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 14px;
    color: #707d9b;
  }
  .bot-cta {
    svg {
      color: red;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .selected-card-cta-button {
    background-color: #311e86;
    width: 105px;
    border-radius: 5px;
    font-family: "GT America Regular";
    cursor: pointer;
  }
  .selected-card-cta-button:disabled {
    background-color: #bdb9ff;
    color: #311e86;
    border: none;
  }

  .card-cta-botton {
    background-color: #311e86 !important;
    width: 105px;
    border-radius: 5px;
    font-family: "GT America Regular";
    cursor: pointer;
  }
}

.footer {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "GT America Regular";
  font-size: 18px;
  color: #19233c;
  line-height: 30px;
  border-top: 1px solid rgba(10, 10, 10, 0.1);
}

.loader {
  // position: absolute;
  // top: 40%;
  // left: 45%;
  display: flex;
  font-size: 24px;
  text-align: center;
  font-family: "GT America Regular";
  color: #19233c;
}

.dotOne {
  animation: visibilityOne 2s linear infinite;
}
@keyframes visibilityOne {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotTwo {
  animation: visibilityTwo 2s linear infinite;
}
@keyframes visibilityTwo {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotThree {
  animation: visibilityThree 2s linear infinite;
}
@keyframes visibilityThree {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.empty-state {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // min-height: 50vh;

  span {
    font-family: "GT America Regular";
    font-size: 24px;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  .sub-text {
    font-family: "GT America Regular";
    font-weight: normal;
    color: grey;
    font-size: 18px;
    margin-top: 5px;
  }
}

.blue-container {
  background-color: #bdb9ff;
  width: 565px;
  height: 393px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin: 30px 0;
}

.desktop-only-container {
  display: flex;
  flex-grow: 1.5;
  align-items: center;
  justify-content: center;
  font-family: "GT America Medium";
  font-size: 24px;
}

.confirm-modal-wrapper {
  width: 718px !important;
  max-width: 718px !important;
  min-height: 529px;
  .modal-dialog {
    max-width: 718px !important;
    height: 529px;
    width: 718px !important;
  }
  .modal-content {
    border-radius: 10px;
    width: 718px !important;
    min-height: 529px;
  }
  .modal-header {
    font-family: "GT America Bold";
  }
  .modal-body {
    font-family: "GT America Medium";
    padding: 35px;
    display: flex;
    flex-direction: column;
  }
  .confirm-modal-image {
    width: 280px;
    height: 280px;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .confirm-modal-title {
    margin-bottom: 30px;
    font-family: "GT America Bold";
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
    text-align: center;
    color: #19233c;
  }
  .confirm-modal-desc {
    font-family: "GT America Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #19233c;
  }
  .confirm-modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    column-gap: 30px;
  }
  .control-btn-white {
    background-color: #ffffff !important;
    color: #311e86 !important;
    border-color: #311e86 !important;
    height: 52px;
    width: 151px;
    svg {
      //   color: #311e86 !important;
      font-size: 24px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .blue-bg {
    background-color: #311e86 !important;
    color: #ffffff !important;
  }

  .lg-btn {
    width: 254px;
  }
}

.modal-stats-container {
  display: flex;
  gap: 40px;
  align-items: flex-end;
  .stats {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .stats-clan-title {
    font-family: "GT America Regular";
    font-size: 12px;
    margin-bottom: 24px;
    span {
      font-size: 18px;
      font-family: GT America Medium;
    }
  }
}

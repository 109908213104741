.faq-page-title {
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 450px) {
    margin-top: 2rem;
  }
}

.faq-wrapper {
  padding-bottom: 1rem;
  margin: 0 auto;
  @media (max-width: 450px) {
    padding: 1rem 1rem;
    // background-color: #333333;
    // background: linear-gradient(180deg, #061425 0%, #082f5e 100%);
  }
  .title {
    font-family: "GT America Bold";
    color: #19233c;
    margin: 0 auto;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 3rem;
    text-align: center;
    @media (max-width: 990px) {
      font-size: 28px;
      letter-spacing: -1.36px;
      line-height: 48px;
    }
  }

  .faq-collapsed {
    //background-color: #;
    border-radius: 5px;
    @media (max-width: 450px) {
      padding: 1rem;
    }
  }
  .collapsed-title {
    color: #19233c !important;
  }

  .faq-collapse {
    margin-bottom: 2rem;
    padding: 20px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25) inset;
    -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25) inset;
    -moz-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25) inset;
    @media (max-width: 600px) {
      padding: 2rem 0 1rem 0;
      margin-bottom: 1rem;
    }
    .collapse-header-title {
      cursor: pointer;
      color: #19233c;
      font-family: "GT America Bold";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 40px;
      // width: 366px;
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 22px;
        // width: 229px;
        padding-right: 10px;
        padding-left: 15px;
      }
    }
    p {
      font-size: 16px;
      font-family: "GT America Regular";
      line-height: 26px;
      letter-spacing: 0;
      margin-bottom: 1rem;
      color: #19233c;
    }
  }
  .faq-ul {
    list-style-type: square;
    li {
      padding-bottom: 1em;
      font-family: "GT America Regular";
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin-left: 1rem;
      color: #19233c;
      @media (max-width: 450px) {
        margin-left: 0;
      }
    }
  }
  a {
    color: #19233c;
    text-decoration: underline;
  }
}

.mobile-padding {
  @media (max-width: 450px) {
    padding: 0rem 1rem;
  }
}

.arrow-container {
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #19233c;
  cursor: pointer;
  @media (max-width: 450px) {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.faq-tabs {
  display: flex;
  justify-content: space-around;
  font-family: "GT America Medium";
  // margin-bottom: 10px;
  li {
    width: 50%;
    text-align: center;
  }

  .nav-link {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    cursor: pointer;
    opacity: 1;
    padding-bottom: 15px;
    padding-top: 15px;
    // color: #fff !important;
    text-align: center;
    text-decoration: none !important;
  }

  // .nav-tabs .nav-item.show .nav-link,
  // .nav-tabs .nav-link.active {
  //   font-weight: 500;
  //   letter-spacing: 0;
  //   line-height: 25px;
  //   text-align: center;
  //   font-family: "GT America Extended Medium";
  // }

  .nav-link:hover {
    // border: none;
    color: #19233c;
  }

  .nav-link.active {
    border-bottom: none;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    opacity: 1;
    // border: none;
    color: #19233c;
  }

  .nav-tabs .nav-link.active {
    color: #19233c !important;
  }

  .nav-link.active {
    opacity: 1;
    // border: none;
    font-size: 20px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #19233c !important;
    // border-radius: 10px;
  }
  @media (max-width: 450px) {
    .nav-tabs .nav-item {
      width: 100%;
    }
  }
}

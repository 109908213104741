.sidebar-wrapper {
  width: 30%;
  height: inherit;
  // transform: translateX(0);
  // transition: 300ms ease-in-out;
  position: relative;
  box-shadow: 0px 0px 4px rgba(205, 216, 237, 0.54);
  padding: 57px 25px;
  overflow: auto;
  will-change: transform, transition, width;

  &::-webkit-scrollbar {
    width: 10px;
    // height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(10, 10, 10, 0.1);
    border-radius: 10px;

    // &:hover {
    //   background: rgba(255, 255, 255, 0.2);
    // }
  }
  @media (max-width: 450px) {
    display: none;
  }
}

.sidebar-closed {
  width: 5%;
  // transform: translateX(-230px);
  // transition: 300ms ease-in-out;
}

.sidebar-container {
  display: flex;
  flex-direction: column;

  hr {
    margin-top: 32px;
    margin-bottom: 32px;
    color: #e7eaf1;
    height: 2px !important;
  }
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #19233c;
}

.sidebar-title {
  font-family: "GT America Regular";
  font-size: 13px;
  line-height: 19px;
  //   color: #19233c;
  .sidebar-clan-name {
    font-family: "GT America Bold";
    font-size: 20px;
    line-height: 30px;
  }
}

.sidebar-collapse-control {
  cursor: pointer;
  svg {
    font-size: 24px;
  }
}

// Layers

.sidebar-layers-container {
  margin-top: 32px;
  color: #19233c;
  font-size: 20px;
}

.sidebar-layer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 24px;
  .title {
    font-family: "DM Sans Bold";
    svg {
      font-size: 24px;
      margin-right: 19px;
    }
  }
  svg {
    font-size: 16px;
    cursor: pointer;
  }
}

.sidebar-layer-collapse-item {
  background: #f6f9ff;
  border-radius: 16px;
  padding: 10px;
  width: 100%;
  height: 97px;
  display: flex;
  margin-bottom: 10px;
  .sidebar-layer-logo {
    background: #bdb9ff;
    border-radius: 16px;
    min-width: 77px;
    height: 77px;
    width: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #1c1c8d;
    }
  }
  .sidebar-layer-desc {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    .title {
      font-family: "GT America Regular";
      font-size: 13px;
      line-height: 19px;
    }
    .desc {
      font-family: "DM Sans Bold";
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.sidebar-power-container {
  display: flex;
  flex-direction: column;
  font-family: "GT America Bold";
  font-size: 20px;
  line-height: 30px;
  margin-top: 32px;
  // margin-bottom: 30px;
}

.sidebar-powerup-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-left: 10px;
    font-size: 18px;
  }
  img {
    margin-right: 20px;
    width: 24px;
    height: 24px;
  }
}

.sidebar-bp-power-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  // width: 150px;
  padding: 10px 20px;
  margin-top: 10px;
  text-align: center;
  img {
    margin-right: 10px;
  }
}

.off-yellow {
  background: #fff3c1;
}

.lime-green {
  background: #dbffe5;
}

.off-red-bck {
  background: #ffcfc5;
}

.light-blue {
  background: #bdeeff;
}

.sidebar-extra-links {
  cursor: pointer;
  color: #19233c;
  font-family: "DM Sans Bold";
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: inherit;
  }
  svg {
    margin-right: 22px;
    font-size: 24px;
  }
}

.sidebar-collapse-cheveron {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.rotate-down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.closed {
  display: none;
}

// .rotate-full {
//   -ms-transform: rotate(360deg);
//   -moz-transform: rotate(360deg);
//   -webkit-transform: rotate(360deg);
//   transform: rotate(360deg);
// }

.skeleton-loading-container {
  background: #f6f9ff;
  border-radius: 16px;
  padding: 10px;
  width: 100%;
  height: 97px;
  display: flex;
  margin-bottom: 10px;
  .skeleton-circle {
    flex-basis: 20%;
    height: 100%;
  }
  .skeleton-rest {
    flex-basis: 70%;
    margin-left: 20px;
  }
}

.zero-mr {
  svg {
    margin-right: 0 !important;
  }
}

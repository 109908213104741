.showcase-wrapper {
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
}
.showcase-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70%;
  @media (max-width: 450px) {
    width: 100%;
  }
}

.showcase-edit-name {
  background-color: #ffffff;
  padding: 19px 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px #cdd8ed80;
  color: #19233c;

  .label {
    font-family: "GT America Regular";
    font-size: 13px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
      font-size: 20px;
    }
  }

  .change-name-input {
    width: 70%;
    @media (max-width: 450px) {
      // border-radius: 0;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .change-name-button {
    font-family: "GT America Regular";
    background-color: #311e86 !important;
  }

  @media (max-width: 1440px) {
    padding: 19px 20px;
  }
}

.three-wrapper {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 450px) {
    // border-radius: 0;
    // padding: 0 1rem;
  }
}

.three-container {
  width: 100%;
  height: 784px;
  cursor: grab;
  @media (max-width: 450px) {
    height: 400px;
  }
  // @media (max-width: 1280px) {
  //   height: 600px;
  // }
}

.three-actions-container {
  position: absolute;
  right: 15%;
  background-color: #ffffff;
  //   height: 209px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 21px 12px;
  .color-selector {
    cursor: pointer;
    width: 34px;
    height: 34px;
    margin-bottom: 22px;
    margin-top: 25px;
    border: 1px solid #919191;
  }
  svg {
    cursor: pointer;
    width: 34px;
    height: 34px;
  }
  @media (max-width: 450px) {
    display: none;
  }
  // @media (max-width: 1440px) {
  //   right: 9%;
  // }
  @media (max-width: 1440px) {
    right: 14%;
    top: 15%;
  }
}

.showcase-verify-twitter {
  padding: 18px 30px;
  background-color: #311e86;
  border-radius: 15px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .trophy-container {
    background-color: #f8de54;
    display: flex;
    width: 93px;
    height: 93px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    svg {
      width: 44px;
      height: 44px;
      color: #311e86;
    }
  }
  .content {
    color: #ffffff;
    font-size: 22px;
    line-height: 30px;
    font-family: "GT America Regular";
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .verify-button {
    width: 105px;
    margin-top: 12px;
    background-color: #ffffff;
    color: #311e86;
  }
}

.color-pallet-popover {
  position: absolute;
  left: 100%;
  z-index: 999;
}

.power-card {
  background-color: #ffffff;
  padding: 23px 31px;
  color: #19233c;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 15px 0px #cdd8ed80;
  border-radius: 16px;
  margin-bottom: 20px;
  @media (max-width: 1440px) {
    padding: 23px 20px;
  }
  @media (max-width: 1280px) {
    padding: 23px 13px;
  }
  .power-desc {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    .title {
      font-family: "GT America Regular";
      font-size: 13px;
      line-height: 19px;
    }
    .name {
      font-family: "GT America Bold";
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 12px;
      @media (max-width: 1280px) {
        font-size: 18px;
      }
    }
  }
}

.powerup-empty-state {
  font-size: 22px;
  text-align: center;
  font-family: "GT America Regular";
  // font-weight: bold;
  margin-top: -1rem;
  padding: 35px 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 15px 0px #cdd8ed80;
  border-radius: 16px;
  .img-container {
    width: 93px;
    height: 93px;
  }
  .description {
    font-family: "GT America Bold";
    font-size: 18px;
    line-height: 24px;
    color: #19233c;
    margin: 24px 0;
  }
  .white-btn {
    background-color: #ffffff !important;
    box-shadow: none !important;
    color: #311e86 !important;
    border: 1px solid #311e86 !important;
    font-size: 14px;
    font-family: "GT America Bold";
    // font-weight: bold;
    line-height: 18px;
    width: 178px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.power-img {
  min-width: 93px;
  height: 93px;
  border-radius: 16px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}

.blue-button {
  font-family: "GT America Regular";
  background-color: #311e86 !important;
  // margin-top: 12px;
  width: 105px;
}

.powerup-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 35%;
  height: 114vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    // height: 0.5em;
    @media (max-width: 450px) {
      width: 0px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(10, 10, 10, 0.1);
    border-radius: 10px;

    // &:hover {
    //   background: rgba(255, 255, 255, 0.2);
    // }
  }
  @media (max-width: 450px) {
    width: 100%;
    margin-top: 26px;
    padding: 0 1rem;
  }
}

.showcase-mobile {
  @media (max-width: 450px) {
    width: 100% !important;
    // margin-top: 65px;
  }
}

.showcase-edit-mobile {
  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.showcase-back-button {
  position: absolute;
  top: 5px;
  cursor: pointer;
  color: #311e86;
  font-size: 30px;
  @media (max-width: 450px) {
    right: 15px;
  }
}

.showcase-three-wrapper {
  width: 75%;
  @media (max-width: 1440px) {
    width: 90%;
  }
  // @media (max-width: 1280px) {
  //   width: 90%;
  // }
}

.powerup-title {
  font-size: 24px;
  margin-bottom: 3rem;
  padding-top: 15px;

  svg {
    margin-right: 10px;
  }
}

//////////////////////////////////////////////////////////
$fill-color: #199473;
$border-color: #d9e2ec;
$text-color: #19233c;
$text-color-inactive: #829ab1;
$text-color-optional: #9fb3c8;

.stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  .step {
    display: table-cell;
    position: relative;
    padding: 1.5rem;
    z-index: 2;
    width: 25%;
    &:last-child .step-bar-left,
    &:last-child .step-bar-right {
      display: none;
    }

    .step-circle {
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      line-height: 1.75rem;
      font-size: 1rem;
      font-weight: 600;
      z-index: 2;
      border: 2px solid $border-color;
    }

    &.done {
      .step-circle {
        background-color: $fill-color;
        border: 2px solid $fill-color;
        color: #ffffff;

        // &:before {
        //   font-family: "FontAwesome";
        //   font-weight: 100;
        //   content: "\f00c";
        // }
        // * {
        //   display: none;
        // }
      }
      .step-title {
        color: $text-color;
      }
    }

    &.editing {
      .step-circle {
        background: #ffffff;
        border-color: $fill-color;
        color: $fill-color;
      }
      .step-title {
        color: $fill-color;
        // text-decoration: underline;
      }
    }

    .step-title {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 600;
    }
    .step-title,
    .step-optional {
      text-align: center;
      color: $text-color-inactive;
    }
    .step-optional {
      font-size: 0.75rem;
      font-style: italic;
      color: $text-color-optional;
    }

    .step-bar-left,
    .step-bar-right {
      position: absolute;
      top: calc(2rem + 5px);
      height: 5px;
      background-color: $border-color;
      border: solid $border-color;
      border-width: 2px 0;
    }
    .step-bar-left {
      width: calc(100% - 2rem);
      left: 50%;
      margin-left: 1rem;
      z-index: -1;
    }
    .step-bar-right {
      width: 0;
      left: 50%;
      margin-left: 1rem;
      z-index: -1;
      transition: width 500ms ease-in-out;
    }

    // &.done .step-bar-left, &.editing .step-bar-left {
    //     background-color:$fill-color;
    //     border-color: $fill-color;
    //     z-index: 3;
    // }
    &.done .step-bar-right {
      background-color: $fill-color;
      border-color: $fill-color;
      z-index: 3;
      width: calc(100% - 2rem);
    }
  }
}

.progress-modal {
  .modal-header {
    padding-left: 2rem;
  }
  .modal-body {
    padding-left: 2rem;
  }
}

.progress-modal-message {
  font-family: "DM Sans Medium";
  font-size: 18px;
  // font-weight: bold;
  // margin-top: 24px;
  // text-align: center;
}

.progress-modal-steps-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  row-gap: 20px;
}

.progress-modal-step {
  display: flex;
  align-items: center;
  .step-title {
    font-family: "DM Sans Bold";
    font-size: 20px;
    margin-left: 20px;
  }
  svg {
    height: 24px;
    width: 24px;
    font-size: 24px;
    margin: 0px;
    color: #311e86;
  }
}

.showcase-tooltip {
  .tooltip-inner {
    max-width: max-content;
    text-align: left;
    ul {
      padding: 5px 18px;
    }
  }
}

.step-loader {
  border: 4px solid #cccccc;
  border-top: 4px solid #311e86;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
